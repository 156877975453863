import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect } from 'react';
import { resetAll } from 'state/resetAction';
import { useTypedDispatch } from 'state/store';

export enum ELoadingState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface ILoadingState {
  loadingState: ELoadingState;
}

export interface IExternalLoadingState {
  vegetations: ILoadingState;
  terrain: ILoadingState;
  terrainRoadmapTexture: ILoadingState;
  terrainSatelliteTexture: ILoadingState;
  terrainCadastreTexture: ILoadingState;
  existingBuildings: ILoadingState;
}

export interface IInternalLoadingState {
  vegetations: ILoadingState;
  terrain: ILoadingState;
  existingBuildings: ILoadingState;
}

export interface LoaderState {
  external: IExternalLoadingState;
  internal: IInternalLoadingState;
}

const initialState: LoaderState = {
  external: {
    vegetations: {
      loadingState: ELoadingState.LOADING,
    },
    terrain: {
      loadingState: ELoadingState.LOADING,
    },
    terrainRoadmapTexture: {
      loadingState: ELoadingState.LOADING,
    },
    terrainSatelliteTexture: {
      loadingState: ELoadingState.LOADING,
    },
    terrainCadastreTexture: {
      loadingState: ELoadingState.LOADING,
    },
    existingBuildings: {
      loadingState: ELoadingState.LOADING,
    },
  },
  internal: {
    vegetations: {
      loadingState: ELoadingState.LOADING,
    },
    terrain: {
      loadingState: ELoadingState.LOADING,
    },
    existingBuildings: {
      loadingState: ELoadingState.LOADING,
    },
  },
};

// Add this line to make the loader state available in the window object
window.cityViewLoaderState = cloneDeep(initialState);

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    clear: () => initialState,
    setExternalVegetationsLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.external.vegetations.loadingState = action.payload;
    },
    setExternalTerrainLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.external.terrain.loadingState = action.payload;
    },
    setExternalTerrainRoadmapTextureLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.external.terrainRoadmapTexture.loadingState = action.payload;
    },
    setExternalTerrainSatelliteTextureLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.external.terrainSatelliteTexture.loadingState = action.payload;
    },
    setExternalTerrainCadastreTextureLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.external.terrainCadastreTexture.loadingState = action.payload;
    },
    setExternalExistingBuildingsLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.external.existingBuildings.loadingState = action.payload;
    },
    setInternalVegetationsLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.internal.vegetations.loadingState = action.payload;
    },
    setInternalTerrainLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.internal.terrain.loadingState = action.payload;
    },
    setInternalExistingBuildingsLoadingState: (state, action: PayloadAction<ELoadingState>) => {
      state.internal.existingBuildings.loadingState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
    // Update the window object with the current state of the loader
    builder.addMatcher(
      () => true, // Matches any action
      (state) => {
        window.cityViewLoaderState = cloneDeep(state);
      },
    );
  },
});

export const {
  setInternalVegetationsLoadingState,
  setInternalTerrainLoadingState,
  setInternalExistingBuildingsLoadingState,
  setExternalVegetationsLoadingState,
  setExternalTerrainLoadingState,
  setExternalTerrainRoadmapTextureLoadingState,
  setExternalTerrainSatelliteTextureLoadingState,
  setExternalTerrainCadastreTextureLoadingState,
  setExternalExistingBuildingsLoadingState,
} = loaderSlice.actions;

interface UseExternalLoaderStoreUpdaterProps {
  vegetations: IQueryState;
  terrain: IQueryState;
  existingBuildings: IQueryState;
}

export const useExternalLoaderStoreUpdater = (props: UseExternalLoaderStoreUpdaterProps) => {
  const { vegetations, terrain, existingBuildings } = props;

  const dispatch = useTypedDispatch();

  const vegetationsQueryState = convertQueryStateToLoadingState(vegetations);
  const terrainQueryState = convertQueryStateToLoadingState(terrain);
  const existingBuildingsQueryState = convertQueryStateToLoadingState(existingBuildings);

  useEffect(() => {
    dispatch(setExternalVegetationsLoadingState(convertQueryStateToLoadingState(vegetations)));
  }, [vegetationsQueryState]);

  useEffect(() => {
    dispatch(setExternalTerrainLoadingState(convertQueryStateToLoadingState(terrain)));
  }, [terrainQueryState]);

  useEffect(() => {
    dispatch(setExternalExistingBuildingsLoadingState(convertQueryStateToLoadingState(existingBuildings)));
  }, [existingBuildingsQueryState]);
};

export interface IQueryState {
  isLoading: boolean;
  isError: boolean;
}

export const convertQueryStateToLoadingState = (queryState: IQueryState): ELoadingState => {
  if (queryState.isLoading) {
    return ELoadingState.LOADING;
  }
  if (queryState.isError) {
    return ELoadingState.ERROR;
  }
  return ELoadingState.SUCCESS;
};
